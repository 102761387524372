<template>
  <div id="reseller-list">
    <user-add></user-add>
    <!-- list filters -->
    <v-card>
      <v-card-title>Resellers list</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- status filter -->
        <v-col cols="12" sm="4">
          <v-select
            class="reseller-status-filter"
            v-model="logic.statusFilter.value"
            placeholder="Select Status"
            :items="statusOptions()"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="logic.searchQuery.value"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="reseller-search-query user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="add-new-reseller mb-4 me-3"
            @click.stop="logic.openUserAddModal()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Reseller</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :options.sync="logic.options.value"
        :headers="logic.tableColumns"
        :items="logic.users.value"
        :item-class="() => 'user-item'"
        :server-items-length="logic.countUsers.value"
        :loading="logic.loading.value"
      >
        <!-- name -->
        <template #[`item.fullName`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span class="font-weight-medium">{{ avatarText(item.fullName) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'reseller-view', params: { id: item.id } }"
                class="reseller-name text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.fullName }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- email -->
        <template #[`item.email`]="{ item }">
          <div class="reseller-item-email">{{ item.email }}</div>
        </template>

        <!-- companyName -->
        <template #[`item.companyName`]="{ item }">
          <div class="reseller-item-company-name">{{ item.companyName }}</div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-edit-dialog :return-value.sync="item.status" large persistent @save="logic.updateStatus(item.id, logic.newStatus.value)">
            <v-chip
              small
              :color="resolveUserStatusVariant(item.status)"
              :class="`${resolveUserStatusVariant(item.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status }}
            </v-chip>
            <template #input>
              <div class="mt-4 title">Update Status</div>
              <v-select
                v-model="logic.newStatus.value"
                :items="statusOptions()"
                item-text="title"
                item-value="value"
                label="Status"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="reseller-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="reseller-list-item-detail" :to="{ name: 'reseller-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="reseller-list-item-login-as-reseller" @click="loginAsReseller(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiLogin }}
                  </v-icon>
                  <span>Login as reseller</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="reseller-list-item-reset-2fa"
                @click="logic.reset2fa(item)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiOnepassword }}
                  </v-icon>
                  <span>Reset 2fa</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiLogin,
  mdiOnepassword,
} from '@mdi/js';
import { ref } from '@vue/composition-api';
import store from '@/store';
import useUsers, { resolveUserStatusVariant, statusOptions } from '@/views/shared/users/useUsers';
import RolesEnum from '@/enums/roles.enum';
import { avatarText } from '@core/utils/filter';
import UserAdd from '@/views/shared/users/_partials/UserAdd.vue';

export default {
  components: {
    UserAdd,
  },
  setup: function () {
    const logic = useUsers(RolesEnum.RESELLER);
    const isAddNewResellerSidebarActive = ref(false);

    return {
      logic,
      isAddNewResellerSidebarActive,
      // icons
      icons: {
        mdiOnepassword,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiLogin,
      },
    };
  },
  methods: {
    avatarText,
    statusOptions() {
      return statusOptions;
    },
    resolveUserStatusVariant,
    async loginAsReseller(id) {
      const token = await store.dispatch('auth/getLoginToken', id);

      const route = this.$router.resolve({
        name: 'dashboard',
        query: {
          action: 'login-as-reseller',
          token,
        },
      });

      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
</style>
